import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import {Container, Row, Col} from 'react-bootstrap'
import AdvertContact from "../components/advert"
import { useStaticQuery, graphql  } from "gatsby"
import Img from "gatsby-image"

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      q1: file(relativePath: { eq: "pexels-cottonbro-5191387.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q2: file(relativePath: { eq: "pexels-alphatradezone-5831511.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q3: file(relativePath: { eq: "pexels-pixabay-38271.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q4: file(relativePath: { eq: "pexels-vlada-karpovich-4050287.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q5: file(relativePath: { eq: "pexels-lukas-577210.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q6: file(relativePath: { eq: "pexels-elevate-1267362.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q7: file(relativePath: { eq: "pexels-dan-nelson-3949100.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return(
  <>
    <Header />
    <SEO title="Services Page" />
    <Container className="mt-2">
      <h1>Opsmorph Services</h1>
      <hr />
      <p>Opsmorph is a data science and cloud solutions research and consultancy company. Whether you are a startup looking to accelerate the development of a minimal viable product for your Software as a Service (SaaS) application, or involved in a research project looking for data science and machine learning support to make better use of your data, Opsmorph is here to work with you to co-design and develop your bespoke tech solution. Some of the services we offer are listed below.</p> 
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-4 bg-light'>
          <h4 className='text-center'>Project Design and Data Strategy</h4>
          <hr />
          <p>Assisting you with problem definition, scoping, co-design, and technical project management.</p>
          <p>Performing data audits and advising on data strategy.</p>
        </Col>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q1.childImageSharp.fluid}
            alt={`Project design and data strategy service`}
          />
        </Col>
      </Row>
      <Row className='services-short-red no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q2.childImageSharp.fluid}
            alt={`Machine Learning service`}
          />
        </Col>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Machine Learning</h4>
          <hr />
          <p>Training, validating, testing, explaining, deploying, and monitoring predictive supervised machine learning models.</p>
          <p>Implementing unsupervised machine learning methods for cluster identification and analysis.</p>
        </Col>
      </Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Geospatial Data Science</h4>
          <hr />
          <p>Sourcing, merging, and analysing geospatial data for the development of all your geospatial data models.</p>
        </Col>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q3.childImageSharp.fluid}
            alt={`Geospatial Data Science service`}
          />
        </Col>
      </Row>
      <Row className='services-short-red no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q4.childImageSharp.fluid}
            alt={`NLP service`}
          />
        </Col>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Deep Learning with Text</h4>
          <hr />
          <p>Collecting, structuring and extracting insights from text using deep learning for natural language processing (NLP).</p>
          <p>Constructing streaming data infrastructure and performing network analysis and NLP for social media analysis.</p>
        </Col>
      </Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Websites, Dashboards and Apps</h4>
          <hr />
          <p>Designing user friendly interfaces for your web apps.</p>
          <p>Developing front-end custom data visualization dashboards to tell your data story.</p>
          <p className="mb-0">Delivering secure and cost-effective website development and cloud hosting for your company and project websites.</p>
        </Col>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q5.childImageSharp.fluid}
            alt={`Websites, Dashboards and Apps service`}
          />
        </Col>
      </Row>
      <Row className='services-short-red no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className="bg-light">
          <Img
            className="h-100 w-100"
            fluid={data.q6.childImageSharp.fluid}
            alt={`Cloud Data Storage service`}
          />
        </Col>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Cloud Data Storage</h4>
          <hr />
          <p>Building secure and fault tolerant data lakes in the cloud to store and catalogue data of various formats, from disparate sources.</p>
          <p className="mb-0">Migrating your on-premise databases to the cloud and redesigning your data access patterns to better meet your needs.</p>
        </Col>
      </Row>
      <Row className='services-short-green no-gutters mt-5 mb-5 shadow-sm'>
        <Col lg={true} md={6} className='p-5 bg-light'>
          <h4 className='text-center'>Cloud Security</h4>
          <hr />
          <p>Establishing private authentication for secure cloud access.</p>
          <p>Securing your cloud environment using Identity Access Management and data encryption at rest and in transit.</p>
          <p className="mb-0">Optimizing cost and performance of your cloud applications.</p>
        </Col>
        <Col lg={true} md={6}>
          <Img
            className="h-100 w-100"
            fluid={data.q7.childImageSharp.fluid}
            alt={`Cloud Security service`}
          />
        </Col>
      </Row>
      <AdvertContact />
    </Container>
    <Footer />
  </>
)
}
export default ServicesPage
